import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SubscriptionApiService } from './subscription-api.service';
import { take } from 'rxjs/operators';
import { SystemService } from '../system.service';
import { SapiSubscriptionStorageService } from './sapi-subscription-storage-service';
import { SapiSubscription } from '../../models/subscription-api/sapi-subscription';
import { SapiAddress } from '../../models/subscription-api/sapi-address';
import { SapiSubscriptionCreate } from '../../models/subscription-api/sapi-subscription-create';
import { SapiSubscriptionUpdate } from '../../models/subscription-api/sapi-subscription-update';
import { SapiDevice } from '../../models/subscription-api/sapi-device';

export interface SubscriptionCheckResult {
  valid: boolean;
  logout: boolean;
  errorMessageId?: string;
  userId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private api: SubscriptionApiService,
    private subscriptionStorage: SapiSubscriptionStorageService,
    private system: SystemService
  ) {}

  public get loginRequired(): boolean {
    // First check if the user already logged in
    if (!this.subscriptionStorage.exists) {
      return true;
    } else {
      const subscription: SapiSubscription = this.subscriptionStorage.load();

      // The user already logged in
      // check if the subscription is up to date
      if (subscription.isExpired) {
        this.subscriptionStorage.delete();
        return true;
      } else {
        return false;
      }
    }
  }

  checkSubscription(deviceId: string): Observable<SubscriptionCheckResult> {
    //console.log('\t\tSubscriptionService.checkSubscription()');

    const subject$$: ReplaySubject<SubscriptionCheckResult> =
      new ReplaySubject();

    // Check if the subscription information is available
    if (!this.subscriptionStorage.exists) {
      subject$$.error('subscription_api.error_no_data');
    } else {
      const sapiSubscription: SapiSubscription =
        this.subscriptionStorage.load();

      this.api.read(sapiSubscription.id).subscribe(
        (subscription) => {
          //console.log('\t\tSubscriptionService.checkSubscription() subscription read');
          this.onCheckSubscriptionRead(subject$$, subscription, deviceId);
        },
        (error) => subject$$.error(error)
      );
    }
    return subject$$.asObservable().pipe(take(1));
  }

  private onCheckSubscriptionRead(
    subject$$: ReplaySubject<SubscriptionCheckResult>,
    sapiSubscription: SapiSubscription,
    deviceId: string
  ): void {
    //console.log('\t\tSubscriptionService.onCheckSubscriptionRead()');

    // Save the updated subscription
    this.subscriptionStorage.save(sapiSubscription);

    // Check the device id
    if (sapiSubscription.device.deviceId !== deviceId) {
      this.subscriptionStorage.delete();

      subject$$.next({
        valid: false,
        logout: true,
        errorMessageId:
          'login_page.the_user_has_registered_another_device_for_the_exam',
        userId: sapiSubscription.userId,
      });
      subject$$.complete();
      return;
    }

    // Check if the subscription is expired
    if (sapiSubscription.isExpired) {
      this.subscriptionStorage.delete();

      subject$$.next({
        valid: false,
        logout: true,
        errorMessageId:
          'login_page.the_exam_subscription_of_the_user_has_expired',
        userId: sapiSubscription.userId,
      });
      subject$$.complete();
      return;
    }

    // All checks passed
    subject$$.next({
      valid: true,
      logout: false,
    });
    subject$$.complete();
  }

  public askForLogin(
    paymentId: string,
    email: string,
    language: string,
    city: string,
    company: string,
    country: string,
    firstName: string,
    lastName: string,
    street: string,
    zip: string
  ): Observable<SapiSubscription> {
    const address: SapiAddress = new SapiAddress({
      city: city,
      company: company,
      country: country,
      firstName: firstName,
      lastName: lastName,
      street: street,
      zip: zip,
    });

    const subscriptionCreate: SapiSubscriptionCreate =
      new SapiSubscriptionCreate(paymentId, email, language, address);

    return this.api.create(subscriptionCreate);
  }

  public login(
    userId: string,
    password: string,
    deviceId: string
  ): Observable<void> {
    const subject$$: ReplaySubject<void> = new ReplaySubject();

    this.api.readByUserId(userId, password).subscribe(
      (subscription) => this.onLoginRead(subject$$, subscription, deviceId),
      (error) => {
        console.error('SubscriptionService.login() error:', error);
        subject$$.error(error);
      }
    );

    return subject$$.asObservable().pipe(take(1));
  }

  private onLoginRead(
    subject$$: ReplaySubject<void>,
    subscription: SapiSubscription,
    deviceId: string
  ): void {
    // Store the updated subscription for future use
    this.subscriptionStorage.save(subscription);

    // Check subscription expiry
    if (subscription.isExpired) {
      subject$$.error(
        'login_page.the_exam_subscription_of_the_user_has_expired'
      );
      subject$$.complete();
      return;
    }

    const subscriptionDeviceId: string = subscription.device.deviceId.trim();

    // If exam ID is not an empty string, then compare it
    // to the current device ID. If subscription device
    // id is equal  to the device id, then just re-login quietly
    if (deviceId === subscriptionDeviceId) {
      this.subscriptionStorage.save(subscription);
      subject$$.next();
      subject$$.complete();
      return;
    }

    // If subscription device ID is an empty string,
    // then registration is required
    // If subscription device ID is NOT an empty string,
    // the device ids are different, tnen
    // force logout the other device simply
    // registering the current device

    this.registerUser(subject$$, subscription, deviceId);
  }

  public logout(): Observable<void> {
    const subject$$: ReplaySubject<void> = new ReplaySubject();

    if (!this.subscriptionStorage.exists) {
      subject$$.error('subscription_api.logout_error_no_data');
    } else {
      // Get the stored subscription
      const storedSubscription: SapiSubscription =
        this.subscriptionStorage.load();

      // Delete the stored subscription
      // Even if the unsubscribe subscription fails
      // the application will continue working as
      // if you were not logged in
      this.subscriptionStorage.delete();

      this.api.read(storedSubscription.id).subscribe(
        (subscription) => {
          this.onLogoutRead(subject$$, subscription, storedSubscription);
        },
        (error) => {
          //console.log('\t\tSubscriptionService.logout() error:', error)
          subject$$.error(error);
        }
      );
    }

    return subject$$.asObservable().pipe(take(1));
  }

  private onLogoutRead(
    subject$$: ReplaySubject<void>,
    subscription: SapiSubscription,
    storedSubscription: SapiSubscription
  ): void {
    // Check the device id on the subscription server
    // This device might be already force logged out
    // In this case just delete subscription on this
    // device
    if (subscription.device.deviceId !== storedSubscription.device.deviceId) {
      this.subscriptionStorage.delete();
    } else {
      this.unregisterUser(subject$$, subscription);
    }
  }

  private registerUser(
    subject$$: ReplaySubject<void>,
    subscription: SapiSubscription,
    deviceId: string
  ) {
    const subscriptionUpdate: SapiSubscriptionUpdate =
      SapiSubscriptionUpdate.fromSubscription(subscription);

    // At least device information must be updated
    subscriptionUpdate.device = new SapiDevice({
      type: subscription.device.type,
      deviceId: deviceId,
      model: this.system.browser,
      operationSystem: this.system.platform,
    });

    // If the subscription start date is null,
    // then set it to the current date
    if (subscriptionUpdate.startDate === null) {
      subscriptionUpdate.startDate = new Date();
    }

    this.api.update(subscription.id, subscriptionUpdate).subscribe(
      (subscription) => {
        this.onRegisterUser(subject$$, subscription);
      },
      (error) => {
        //console.log('\t\tSubscriptionService.login() error:', error)
        subject$$.error(error);
      }
    );
  }

  private onRegisterUser(
    subject$$: ReplaySubject<void>,
    subscription: SapiSubscription
  ) {
    // Store the updated subscription for future use
    this.subscriptionStorage.save(subscription);

    // Notify subscribers that the login was successfull
    subject$$.next();
    subject$$.complete();
  }

  private unregisterUser(
    subject$$: ReplaySubject<void>,
    subscription: SapiSubscription
  ) {
    const subscriptionUpdate: SapiSubscriptionUpdate =
      SapiSubscriptionUpdate.fromSubscription(subscription);

    // Device information must be cleared
    subscriptionUpdate.device = new SapiDevice({
      type: subscription.device.type,
      deviceId: '',
      model: '',
      operationSystem: '',
    });

    this.api.update(subscription.id, subscriptionUpdate).subscribe(
      () => this.onUnregisterUser(subject$$),
      (error) => {
        //console.log('\t\tSubscriptionService.logout() error:', error)
        subject$$.error(error);
      }
    );
  }

  private onUnregisterUser(subject$$: ReplaySubject<void>) {
    // Store the updated subscription for future use
    this.subscriptionStorage.delete();

    // Notify subscribers that the login was successfull
    subject$$.next();
    subject$$.complete();
  }
}
