import { Injectable } from "@angular/core";
import { AppConfigService } from "../app-config/app-config.service";
import { SubscriptionApiParam } from "../../models/subscription-api/enums/subscription-api-param.enum";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionApiUrlService {

    readonly baseUrl: string;
    readonly createQuery: string;
    readonly readQuery: string;
    readonly readByUserIdQuery: string;
    readonly updateQuery: string;

    constructor(
        config: AppConfigService
    ) {
        // The light version of the application does not use the service
        // and does not have the configuration settings
        if(!config.subscriptionApi) {
            return;
        }

        this.baseUrl = config.subscriptionApi.baseUrl;

        this.createQuery = config
            .subscriptionApi
            .query
            .create
            .replace(
                SubscriptionApiParam.PRODUCT_ID,
                config.subscriptionApi.productId
            );

        this.readQuery = config
            .subscriptionApi
            .query
            .read
            .replace(
                SubscriptionApiParam.PRODUCT_ID,
                config.subscriptionApi.productId
            );

        this.readByUserIdQuery = config
            .subscriptionApi
            .query
            .readByUserId
            .replace(
                SubscriptionApiParam.PRODUCT_ID,
                config.subscriptionApi.productId
            );

        this.updateQuery = config
            .subscriptionApi
            .query
            .update
            .replace(
                SubscriptionApiParam.PRODUCT_ID,
                config.subscriptionApi.productId
            );
    }

    public getCreateUrl(): string {

        return this.baseUrl.concat(this.createQuery);
    }


    public getReadUrl(
        subscriptionId: string
    ): string {

        return this.baseUrl.concat(
            this.readQuery
                .replace(
                    SubscriptionApiParam.SUSBCRIPTION_ID,
                    subscriptionId
                )
        );
    }

    public getReadByUserIdUrl(
        userId: string,
        password: string
    ): string {

        return this.baseUrl.concat(
            this.readByUserIdQuery
                .replace(
                    SubscriptionApiParam.USER_ID,
                    userId
                )
                .replace(
                    SubscriptionApiParam.PASSWORD,
                    password
                )
        );
    }

    public getUpdateUrl(subscriptionId: string): string {

        return this.baseUrl.concat(
            this.updateQuery
                .replace(
                    SubscriptionApiParam.SUSBCRIPTION_ID,
                    subscriptionId
                )
        );
    }

}
